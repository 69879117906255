<template>
  <v-container class="py-12 secondary" fluid id="contact-us">
    <v-row class="py-12" justify="center" align="center">
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="6"
            class="d-flex align-center flex-column"
          >
            <v-img
              alt="Gremti Logo"
              contain
              src="../assets/logo_white.svg"
              transition="scale-transition"
              width="180"
            />
            <div class="d-flex justify-center align-center flex-column">
              <v-avatar size="40" color="primary" class="mt-5 mb-3">
                <svg-icon
                  class="icon-size secondary--text"
                  type="mdi"
                  :path="mapPath"
                ></svg-icon>
              </v-avatar>
              <p class="text-center font-weight-medium white--text">
                Calle Av Jazmines #42 <br />Col. La Loma C.P. 63137 <br />
                Tepic, Nayarit. México
              </p>
            </div>
            <div class="d-flex justify-center align-center flex-column">
              <v-avatar size="40" color="primary" class="mt-5 mb-3">
                <svg-icon
                  class="icon-size secondary--text"
                  type="mdi"
                  :path="phonePath"
                ></svg-icon>
              </v-avatar>
              <a
                href="tel:332-832-68-70"
                class="text-center font-weight-medium white--text"
              >
                +52 (332) 832 68-70
              </a>
            </div>
            <div class="d-flex justify-center align-center flex-column">
              <v-avatar size="40" color="primary" class="mt-5 mb-3">
                <svg-icon
                  class="icon-size secondary--text"
                  type="mdi"
                  :path="emailPath"
                ></svg-icon>
              </v-avatar>
              <a
                href="mailto:soporte@gremti.com"
                class="text-center font-weight-medium white--text"
              >
                soporte@gremti.com
              </a>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card class="px-6 py-8 rounded-lg">
              <v-card-text>
                <h2 class="secondary--text">¿Necesitas más información?</h2>
                <h4 class="my-1">
                  Contáctanos y te responderemos a la brevedad
                </h4>

                <v-text-field
                  type="text"
                  outlined
                  hide-details
                  class="mt-5 rounded-lg"
                  label="Nombre"
                  id="subName"
                />
                <v-text-field
                  type="text"
                  outlined
                  hide-details
                  class="mt-5 rounded-lg"
                  label="Correo electrónico"
                  id="subEmail"
                />
                <v-text-field
                  type="text"
                  label="Mensaje"
                  outlined
                  hide-details
                  class="mt-5 rounded-lg"
                ></v-text-field>
                <div
                  class="d-flex flex-column my-4 justify-center align-center"
                >
                  <div
                    class="g-recaptcha"
                    data-sitekey="6LeYL6oUAAAAACnxSmpvYHDBeIxpy_PJV_b66DSc"
                    data-rule="required"
                    data-msg="¿En qué podemos ayudarte?"
                  ></div>
                  <v-checkbox
                    v-model="checkbox"
                    :label="`Acepto el aviso de privacidad`"
                  ></v-checkbox>
                  <v-btn
                    color="secondary"
                    outlined
                    rounded
                    @click="downloadAviso"
                  >
                    <v-icon class="mr-1" dark> mdi-download </v-icon>
                    descargar aviso
                  </v-btn>
                </div>
                <div class="d-flex justify-center align-center">
                  <v-btn
                    type="submit"
                    color="primary"
                    block
                    large
                    depressed
                    rounded
                    :disabled="!checkbox"
                  >
                    Enviar mensaje
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.icon-size {
  height: 20px;
}
</style>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMapMarker, mdiPhone, mdiEmailOutline } from "@mdi/js";

export default {
  props: ["image", "title", "text"],
  components: {
    SvgIcon,
  },
  data: () => ({
    checkbox: true,
    mapPath: mdiMapMarker,
    phonePath: mdiPhone,
    emailPath: mdiEmailOutline,
  }),
  methods: {
    downloadAviso() {
      window.open(
        "https://gremti.com/AVISO DE PRIVACIDAD CORTO - PROVEEDORES.docx",
        "_blank"
      );
    },
  },
};
</script>
